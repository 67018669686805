<template>
  <footer>
    <div class="flex justify-center items-center space-x-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8 justify-center items-center"
      >
        <a
          xlink:href="https://www.facebook.com/DanbyVolunteerFireCompany"
          target="_blank"
          class="w-8 h-8 hover:fill-danby-yellow"
        >
          <path
            d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
          />
        </a>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8 justify-center items-center"
      >
        <a
          xlink:href="https://www.instagram.com/DanbyFire/"
          target="_blank"
          class="w-8 h-8 hover:fill-danby-yellow"
        >
          <path
            d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
          />
        </a>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8 justify-center items-center"
      >
        <a
          xlink:href="https://twitter.com/DanbyFire"
          target="_blank"
          class="w-8 h-8 hover:fill-danby-yellow"
        >
          <path
            d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
          />
        </a>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8 justify-center items-center"
      >
        <a
          xlink:href="https://www.paypal.com/donate/?hosted_button_id=6VPGJH6FFYKNW&fbclid=IwAR1gMQXZOjXCURDdBpkVw5gX4dRQBNhlYNr6JmLpY6wlfOq83tWKK0E5zUw"
          target="_blank"
          class="w-8 h-8 hover:fill-danby-yellow"
        >
          <path
            d="M 17.74259,7.9910182 C 17.919059,6.4186891 17.605828,5.3014695 16.710252,4.2826122 15.636739,3.0626259 13.701474,2.5399844 11.222099,2.5399844 H 4.028087 A 1.0293966,1.0276658 0 0 0 3.0119254,3.40616 L 0.01491074,22.372469 a 0.61763802,0.61659954 0 0 0 0.6102852,0.713493 H 4.6780774 l -0.2970544,1.87916 a 0.92351583,0.92196316 0 0 0 0.9117519,1.065836 h 3.7440626 c 0.630873,0 1.1661585,-0.455109 1.2676275,-1.073177 l 0.03677,-0.190852 0.705871,-4.467409 0.04411,-0.240768 0.0014,-0.01028 a 0.51616888,0.51530105 0 0 1 0.511757,-0.436023 h 0.558814 c 1.861738,0 3.566125,-0.375833 4.919046,-1.335966 0.557345,-0.396386 1.047044,-0.885261 1.460273,-1.475434 a 7.2675399,7.2553212 0 0 0 1.294099,-3.222467 C 20.192483,11.74934 20.027781,10.119755 18.998383,8.9482162 A 3.9514125,3.9447691 0 0 0 17.880824,8.08204 L 17.74259,7.9924865 Z M 6.6883421,15.488575 a 1.0220438,1.0203254 0 0 1 0.4720516,-0.115979 h 2.1117348 c 4.1469965,0 7.3925365,-1.679501 8.3410535,-6.5418282 l 0.0044,-0.023483 c 0.319111,0.1820436 0.588226,0.3963857 0.805869,0.643026 0.80293,0.9146222 0.998516,2.2535242 0.661755,3.9785342 -0.399993,2.050927 -1.273509,3.386894 -2.445551,4.219302 -1.179394,0.836815 -2.708785,1.196498 -4.474935,1.196498 h -0.558816 a 1.2838046,1.2816462 0 0 0 -1.269099,1.07758 l -0.04412,0.240768 -0.7058705,4.46741 -0.03529,0.190852 -0.0014,0.0059 a 0.51763945,0.51676914 0 0 1 -0.511753,0.43457 H 5.2943049 A 0.15588006,0.15561797 0 0 1 5.1398955,25.081179 l 0.305878,-1.937884 z"
          />
        </a>
      </svg>
    </div>
    <div class="py-2 text-danby-yellow text-center">
      <router-link :to="{ name: 'admin' }"
        >© DVFC {{ currentYear }}
      </router-link>
      <p class="text-sm">
        Danby Volunteer Fire Company is a non-profit organization.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return { currentYear: new Date().getFullYear() };
  },
};
</script>
