<template>
  <div>
    <volunteer-roles></volunteer-roles>
  </div>
</template>

<script>
import VolunteerRoles from "@/components/subpages/VolunteerRoles.vue";

export default {
  components: {
    VolunteerRoles,
  },
};
</script>
